<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p v-if="systemUpdating" class="modal-card-title">
              Atualizar informações do sistema de integração
            </p>
            <p v-else class="modal-card-title">Inserir sistema de integração</p>
          </header>
          <section class="modal-card-body" style="overflow-y: auto !important">
            <b-field
              label="Nome"
              :message="invalidName.message"
              :type="invalidName.style"
            >
              <b-input
                type="text"
                v-model="name"
                placeholder="Nome"
                @input="
                  invalidName.message = '';
                  invalidName.style = '';
                "
                required
              >
              </b-input>
            </b-field>
            <b-field label="Código customizado">
              <b-input
                type="text"
                v-model="custom_code"
                placeholder="Código customizado"
                @input="
                  invalidCustomCode.message = '';
                  invalidCustomCode.style = '';
                "
                required
              >
              </b-input>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Fechar
            </button>
            <button
              v-if="!!systemUpdating"
              class="button is-primary"
              @click="updateSystem"
            >
              Atualizar
            </button>
            <button v-else class="button is-primary" @click="addSystem">
              Gravar
            </button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>

export default {
  name: 'ModalInsertSystem',
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    systemUpdating: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      name: '',
      custom_code: '',
      invalidName: {
        message: '',
        style: ''
      },
      invalidCustomCode: {
        message: '',
        style: ''
      }
    }
  },
  created () {
    if (this.systemUpdating) {
      this.name = this.attendantUpdating.name
      this.custom_code = this.attendantUpdating.custom_code
    }
  },
  methods: {
    addSystem () {
      if (!this.validateForm()) return

      this.$emit('addSystem', {
        name: this.name,
        custom_code: this.custom_code
      })
    },
    validateForm () {
      let isValid = true

      if (this.name.length === 0) {
        this.invalidName.message = 'Informe um nome!'
        this.invalidName.style = 'is-danger'
        isValid = false
      } else {
        this.invalidName.message = ''
        this.invalidName.style = ''
      }

      if (this.custom_code.length === 0) {
        this.invalidCustomCode.message = 'Informe um code_name!'
        this.invalidCustomCode.style = 'is-danger'
        isValid = false
      } else {
        this.invalidCustomCode.message = ''
        this.invalidCustomCode.style = ''
      }

      return isValid
    },
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}

// /deep/ .label-password > .label {
//   color: red
// }
</style>
