<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Integrações
    </hero-bar>
    <section class="section is-main-section">
      <b-loading v-if="fetchingIntegrations" :is-full-page="true" v-model="fetchingIntegrations" :can-cancel="false">
      </b-loading>
      <ModalExternalSystem
        v-if="isComponentModalActive2"
        v-on:addSystem="handleAddSystem"
        v-on:closeModal="closeModal2"
        :isComponentModalActive="isComponentModalActive2"
        :systemUpdating="systemUpdating"
        v-on:updateExternalSystem="handleUpdateSystem" />
      <systems-list v-else :systems='allSystems' v-on:updateSystem="openUpdateSystem" v-on:removeSystem="removeSystem"/>
      <FabButton v-if="updateSystem" v-on:addClick="handleAddClick" iconClass="fas fa-pen-fancy" title="Novo sistema de integração" />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import { mapActions, mapGetters } from 'vuex'
import SystemsList from '@/components/integrations/SystemsList.vue'
import FabButton from '@/components/FabButton.vue'
import ModalExternalSystem from '../components/modals/ModalExternalSystem.vue'

export default {
  name: 'ExternalSystemsScreen',
  components: {
    HeroBar,
    TitleBar,
    SystemsList,
    FabButton,
    ModalExternalSystem
  },
  data () {
    return {
      isComponentModalActive: false,
      isComponentModalActive2: false,
      systemUpdating: null,
      systemDeletingId: 0
    }
  },
  computed: {
    ...mapGetters(['allSystems', 'fetchingIntegrations']),
    titleStack () {
      return ['Configurações', 'Integrações']
    }
  },
  methods: {
    ...mapActions(['updateSystem', 'fetchSystems', 'addSystem', 'deleteSystems']),
    handleAddSystem (system) {
      this.closeModal2()
      this.addSystem(system)
      // this.configSettings(system)
    },
    handleAddClick () {
      this.isComponentModalActive2 = !this.isComponentModalActive2
    },
    openModal () {
      this.isComponentModalActive = !this.isComponentModalActive
    },
    closeModal () {
      this.isComponentModalActive = false
    },
    openModal2 () {
      this.isComponentModalActive2 = !this.isComponentModalActive2
    },
    closeModal2 () {
      this.isComponentModalActive2 = false
    },
    openUpdateSystem (system) {
      this.$router.push({ path: `/integracoes/${system.id}` })
      this.systemUpdating = system
      this.openModal()
    },
    handleUpdateSystem (system) {
      this.closeModal()
      this.updateSystem(system).catch(() =>
        this.$buefy.dialog.alert({
          title: 'Erro',
          message: 'Ocorreu um <b>erro</b> ao atualizar os dados do sistema. Recarregue a tela e tente novamente!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      )
    },
    async removeSystem (system) {
      try {
        this.systemDeletingId = system.id
        await this.deleteSystems(system.id)
        this.$buefy.toast.open('Sistema de Integração excluído com sucesso!')
      } catch (error) {
        this.$buefy.toast.open('Não foi possível excluir o sistema de integração!')
      } finally {
        this.systemDeletingId = 0
      }
    }
  },
  created () {
    this.fetchSystems()
  }
}
</script>
