<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p v-if="permissionUpdating" class="modal-card-title">
              Atualizar informações da permissão
            </p>
            <p v-else class="modal-card-title">
              Insira a permissão a ser cadastrada
            </p>
          </header>
          <b-loading v-if="loading" :is-full-page="true" v-model="loading" :can-cancel="false"></b-loading>
          <section class="modal-card-body" v-else>
            <b-field label="Nome">
              <b-input type="text" v-model="permission_name" placeholder="Nome" required>
              </b-input>
            </b-field>
            <b-tabs type="is-boxed">
              <b-tab-item label="Geral" icon="account-box">
                <div>
                  <div v-if="computedPermissionItems.length > 0">
                    <b-table :data="computedPermissionItems()" :bordered="true" :striped="false" :narrowed="false"
                      :hoverable="false" :loading="false" :focusable="false" :mobile-cards="true">

                      <b-table-column field="permission_type" label="Tipo de permissão" v-slot="props" searchable>
                        {{ props.row.permission_type_description }}
                      </b-table-column>

                      <b-table-column field="permission_key" label="Permissão" v-slot="props" searchable>
                        {{ props.row.permission_key_description }}
                      </b-table-column>

                      <b-table-column field="has_permission" label="Status" v-slot="props" searchable>
                        <IsActiveTag :active=props.row.has_permission></IsActiveTag>
                      </b-table-column>

                      <b-table-column field="delete" width="200" label="Remover" v-slot="props">
                        <b-button v-if="!props.row.has_permission" type="is-success"
                          @click="addPermissionItem(null, props.row)">Ativar</b-button>
                        <b-button v-else type="is-warning"
                          @click="removePermissionItem(null, props.row.permission_key, props.row.permission_type)">Remover</b-button>
                      </b-table-column>
                    </b-table>
                  </div>
                  <div v-else>
                    <p>Não há integrações cadastradas</p>
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item :label="account.phone" icon="account-box" v-for="account in allAccounts" :key="account.phone">
                <div>
                  <div v-if="computedPermissionItems.length > 0">
                    <b-table :data="computedPermissionItems(account.phone)" :bordered="true" :striped="false"
                      :narrowed="false" :hoverable="false" :loading="false" :focusable="false" :mobile-cards="true">

                      <b-table-column field="permission_type" label="Tipo de permissão" v-slot="props" searchable>
                        {{ props.row.permission_type_description }}
                      </b-table-column>

                      <b-table-column field="permission_key" label="Permissão" v-slot="props" searchable>
                        {{ props.row.permission_key_description }}
                      </b-table-column>

                      <b-table-column field="has_permission" label="Status" v-slot="props" searchable>
                        <IsActiveTag :active=props.row.has_permission></IsActiveTag>
                      </b-table-column>

                      <b-table-column field="delete" width="200" label="Remover" v-slot="props">
                        <b-button v-if="!props.row.has_permission" type="is-success"
                          @click="addPermissionItem(account.phone, props.row)">Ativar</b-button>
                        <b-button v-else type="is-warning"
                          @click="removePermissionItem(account.phone, props.row.permission_key, props.row.permission_type)">Remover</b-button>
                      </b-table-column>
                    </b-table>
                  </div>
                  <div v-else>
                    <p>Não há integrações cadastradas</p>
                  </div>
                </div>
              </b-tab-item>
            </b-tabs>
            <b-field label="Ativo">
              <b-switch v-model="enabled" :true-value="true" :false-value="false"></b-switch>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Fechar
            </button>
            <button v-if="!!permissionUpdating" class="button is-primary" @click="updatePermission">
              Atualizar
            </button>
            <button v-else class="button is-primary" @click="addPermission">
              Gravar
            </button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import YesNoTag from '../commons/YesNoTag.vue'
import IsActiveTag from '../commons/IsActiveTag.vue'

export default {
  name: 'ModalInsertPermission',
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    permissionUpdating: {
      type: Object,
      required: false
    },
    availablePermissionItems: {
      type: Array,
      required: true
    },
    allAccounts: {
      type: Array,
      required: true
    }
  },
  async created() {
    if (this.permissionUpdating) {
      this.loading = true;
      try {
        this.permission_name = this.permissionUpdating.permission_name;
        this.enabled = this.permissionUpdating.enabled;
        this.permissionsItems = JSON.parse(JSON.stringify(this.permissionUpdating.permission_items));
      }
      finally {
        this.loading = false;
      }
    }
  },
  data() {
    return {
      permission_name: '',
      enabled: true,
      permissionsItems: [],
      loading: false,
      selectedPermissionItem: null,
      selectedAccountId: null,
      permissionPhone: null,
    };
  },
  methods: {
    computedPermissionItems(selectedPermissionPhone) {
      const computedPermissions = [];
      this.availablePermissionItems.map(permission => {
        permission.permissions_keys.map(permissionKey => {
          if (selectedPermissionPhone && permission.permission_mode === 'account') {
            computedPermissions.push({
              account_id: permission.account_id,
              permission_type: permission.permission_type,
              permission_type_description: permission.permission_type_description,
              permission_key: permissionKey.permission_key,
              permission_key_description: permissionKey.permission_key_description,
              has_permission: !!this.permissionsItems.find(permisisonItem => permisisonItem.account_id === selectedPermissionPhone && permisisonItem.permission_key === permissionKey.permission_key),
              status_description: this.permissionsItems.find(permisisonItem => permisisonItem.account_id === selectedPermissionPhone && permisisonItem.permission_key === permissionKey.permission_key) ? 'Ativo' : 'Inativo',
            });
          } else if (!selectedPermissionPhone &&  permission.permission_mode === 'global') {
            computedPermissions.push({
              account_id: null,
              permission_type: permission.permission_type,
              permission_type_description: permission.permission_type_description,
              permission_key: permissionKey.permission_key,
              permission_key_description: permissionKey.permission_key_description,
              has_permission: !!this.permissionsItems.find(permisisonItem => permisisonItem.permission_key === permissionKey.permission_key),
              status_description: this.permissionsItems.find(permisisonItem => permisisonItem.permission_key === permissionKey.permission_key) ? 'Ativo' : 'Inativo',
            });
          }
        });
      });
      return computedPermissions;
    },
    addPermissionItem(account_id, permissionItem) {
      this.permissionsItems.push({
        account_id: account_id,
        permission_type: permissionItem.permission_type,
        permission_key: permissionItem.permission_key,
      });
    },
    removePermissionItem(account_id, permission_key, permission_type) {
      const index = this.permissionsItems.findIndex(permissionItem => permissionItem.account_id === account_id && permissionItem.permission_key === permission_key && permissionItem.permission_type === permission_type);
      this.permissionsItems.splice(index, 1);
    },
    addPermission() {
      this.$emit('addPermission', {
        permission_name: this.permission_name,
        permission_items: this.permissionsItems,
        enabled: this.enabled,
      });
    },
    closeModal() {
      this.$emit('closeModal');
    },
    updatePermission() {
      this.emitUpdatePermission({
        id: this.permissionUpdating.id,
        permission_name: this.permission_name,
        permission_items: this.permissionsItems,
        enabled: this.enabled,
      });
    },
    emitUpdatePermission(permission) {
      this.$emit('updatePermission', permission);
    }
  },
  components: { YesNoTag, IsActiveTag }
}
</script>

<style scoped>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}
</style>
