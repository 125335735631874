<template>
  <div>
    <div v-if="allPermissions.length > 0">
      <b-table
        :data="allPermissions"
        :bordered="true"
        :striped="true"
        :narrowed="false"
        :hoverable="false"
        :loading="false"
        :focusable="false"
        :mobile-cards="true"
      >
        <b-table-column :numeric="true" field="id" label="ID" v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="permission_name" label="Nome" v-slot="props">
          {{ props.row.permission_name }}
        </b-table-column>

        <b-table-column field="enabled" label="Ativo" v-slot="props">
          <YesNoTag :isYes="props.row.enabled"></YesNoTag>
        </b-table-column>

        <b-table-column field="permission_items" label="Quant. de itens de permissões" v-slot="props">
          {{ props.row.permission_items.length }}
        </b-table-column>

        <b-table-column field="update_delete" label="" v-slot="props">
          <dropdown-tag :isDeleting="false">
            <dropdown-item-tag @click="updatePermission(props.row)">Detalhes</dropdown-item-tag>
            <dropdown-item-tag @click="confirmDeletePermission(props.row)">Excluir</dropdown-item-tag>
          </dropdown-tag>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import DropdownTag from '../commons/DropdownTag.vue'
import DropdownItemTag from '../commons/DropdownItemTag.vue'
import YesNoTag from '../commons/YesNoTag.vue'

export default {
  name: 'Permissions',
  props: {
    permissions: {
      type: Array,
      required: true
    }
  },
  components: {
    DropdownTag,
    DropdownItemTag,
    YesNoTag
  },
  computed: {
    allPermissions: {
      get() {
        return this.permissions;
      },
      set() {
        // do nothing
      }
    }
  },
  methods: {
    updatePermission(permission) {
      this.$emit('updatePermission', permission);
    },
    confirmDeletePermission(permission) {
      this.$buefy.dialog.confirm({
        title: 'Excluir permissão',
        message: 'Deseja <b>excluir</b> esta permissão?',
        confirmText: 'Excluir Permissão',
        type: 'is-danger',
        hasIcon: true,
        cancelText: 'Cancelar',
        onConfirm: () => this.$emit('removePermission', permission)
      });
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
