<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <section class="section is-main-section">
      <b-loading v-if="fetchingSettings" :is-full-page="true" v-model="fetchingSettings" :can-cancel="false"></b-loading>
      <Configs
        v-else
        :settings='getPhoneSettings'
        v-on:saveSettings="handleAddSettings"
        :modal="isModalActive"
        v-on:hideModal="hideModal"
        v-on:showModal="showModal"
        :allAttendants="allAttendantsEnabled"
        :businessHours="allEnabledBusinessHours"
        :ticketCloseReasons="allReasons"
        :questions="allContactsFieldsQuestions"
      />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import { mapActions, mapGetters } from 'vuex'
import Configs from '@/components/settings/Configs.vue'

export default {
  name: 'Configurations',
  components: {
    TitleBar,
    Configs
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters(['allSettings', 'fetchingSettings', 'isModalActive', 'allAttendantsEnabled', 'allEnabledBusinessHours', 'allReasons', 'fetchingContactsFieldsQuestions', 'allContactsFieldsQuestions']),
    titleStack () {
      return ['Configurações', 'Configurações', this.$route.params.phone]
    },
    getDepartments () {
      return this.allDepartments.filter(d => d.enabled)
    },
    getPhoneSettings () {
      return this.allSettings.filter(s => s.phone === this.$route.params.phone)[0]
    },
    getPhone () {
      return this.$route.params.phone
    }
  },
  methods: {
    ...mapActions(['saveSettings', 'fetchSettings', 'showModal', 'hideModal', 'fetchAttendants', 'fetchBusinessHours', 'fetchReasons', 'addContactFieldQuestion', 'deleteContactFieldQuestion', 'fetchContactsFieldsQuestions']),
    handleAddSettings (settings) {
      this.saveSettings(settings)
        .catch(err => {
          this.$buefy.dialog.alert({
            title: 'Erro',
            message: err.response.data.message || err.response.data.details.join('<br>') || err.response.data.error,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        })
    }
  },
  created () {
    this.fetchSettings()
    this.fetchAttendants()
    this.fetchBusinessHours()
    this.fetchReasons()
    this.fetchContactsFieldsQuestions()
  }
}
</script>
