<template>
  <div>
    <div v-if="allSystems.length > 0">
      <b-table
        :data="allSystems"
        :bordered="true"
        :striped="true"
        :narrowed="false"
        :hoverable="false"
        :loading="false"
        :focusable="false"
        :mobile-cards="true"
      >
        <b-table-column :numeric="true" field="id" label="ID" v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="name" label="Nome" v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column field="update_delete" label="" v-slot="props">
            <dropdown-tag :isDeleting="false">
              <dropdown-item-tag @click="updateSystem(props.row)">Detalhes</dropdown-item-tag>
              <dropdown-item-tag @click="confirmDeleteSystem(props.row)">Excluir</dropdown-item-tag>
            </dropdown-tag>
        </b-table-column>

        <!--b-table-column
          field="update"
          width="200"
          label="Detalhes"
          v-slot="props"
        >
          <b-button type="is-info" @click="updateSystem(props.row)"
            >Detalhes</b-button
          >
        </b-table-column-->
      </b-table>
    </div>
  </div>
</template>

<script>
import DropdownTag from '../commons/DropdownTag.vue'
import DropdownItemTag from '../commons/DropdownItemTag.vue'
export default {
  name: 'Integrations',
  props: {
    systems: {
      type: Array,
      required: true
    }
  },
  components: {
    DropdownTag,
    DropdownItemTag
  },
  computed: {
    allSystems: {
      get () {
        return this.systems
      },
      set () {
        // do nothing
      }
    }
  },
  methods: {
    updateSystem (system) {
      this.$emit('updateSystem', system)
    },
    confirmDeleteSystem (system) {
      this.$buefy.dialog.confirm({
        title: 'Excluir integração',
        message: 'Deseja <b>excluir</b> este sistema de integração?',
        confirmText: 'Excluir Sistema de Integração',
        type: 'is-danger',
        hasIcon: true,
        cancelText: 'Cancelar',
        onConfirm: () => this.$emit('removeSystem', system)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
