<template>
  <div>
    <div v-if="allQuestions.length > 0">
      <b-table :data="allQuestions" :bordered="true" :striped="true" :narrowed="false" :hoverable="false"
        :loading="false" :focusable="false" :mobile-cards="true">

        <b-table-column field="id" label="ID" v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="custom_field" label="Campo customizado" v-slot="props">
          <YesNoTag :isYes="props.row.custom_field" />
        </b-table-column>

        <b-table-column field="field_key" label="Campo" v-slot="props">
          {{ props.row.field_key }}
        </b-table-column>

        <b-table-column field="question" label="Pergunta" v-slot="props">
          {{ props.row.question }}
        </b-table-column>

        <b-table-column field="validation_regex" label="Regex de validação" v-slot="props">
          {{ props.row.validation_regex }}
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>

import mixin from '../../utils/mixins'
import YesNoTag from '../commons/YesNoTag.vue'

export default {
  name: 'ContactsFieldsQuesitons',
  props: {
    questions: {
      type: Array,
      required: true
    }
  },
  components: {
    YesNoTag
  },
  mixins: [mixin],
  computed: {
    allQuestions: {
      get() {
        return this.questions
      },
      set() {
        // do nothing
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>

</style>
