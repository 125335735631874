var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"section is-main-section"},[_c('a',{staticClass:"button is-info mb-2",on:{"click":() => this.$router.push('/integracoes')}},[_c('b-icon',{attrs:{"icon":"arrow-left","custom-size":"default"}}),_c('span',[_vm._v("Lista de sistemas")])],1),_c('a',{staticClass:"button is-success is-pulled-right mb-2",on:{"click":() => this.openAddIntegration()}},[_c('span',[_vm._v("Adicionar integração")]),_c('b-icon',{attrs:{"icon":"plus","custom-size":"default"}})],1),_c('hr'),_c('b-field',{attrs:{"label":"ID"}},[_c('b-input',{attrs:{"type":"text","disabled":"","required":""},model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}})],1),_c('b-field',{attrs:{"label":"Código customizado"}},[_c('b-input',{attrs:{"type":"text","disabled":"","required":""},model:{value:(_vm.custom_code),callback:function ($$v) {_vm.custom_code=$$v},expression:"custom_code"}})],1),_c('b-field',{attrs:{"label":"Nome"}},[_c('b-input',{attrs:{"type":"text","disabled":"","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('b-field',{attrs:{"label":"Integrações"}},_vm._l((_vm.external_system_integrations),function(integration){return _c('div',{key:integration.id,staticClass:"card"},[_c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v(_vm._s(integration.id)+" - "+_vm._s(integration.name)+_vm._s(integration.custom_code ? ` - (${integration.custom_code})` : ''))])]),_c('div',{staticClass:"card-content"},[_c('b-field',[_c('b-checkbox',{attrs:{"value":integration.send_menu,"disabled":""}},[_vm._v("Enviar menu após completar requisição")])],1),_c('b-field',{attrs:{"label":"Parâmetros ativos"}},[(
                _vm.getFilteredParams(
                  integration.external_system_integration_params,
                  true,
                ).length
              )?_c('b-table',{attrs:{"data":_vm.getFilteredParams(
                  integration.external_system_integration_params,
                  true,
                ),"columns":_vm.integrationsTableColumns}}):_c('div',[_c('b-message',{staticClass:"warning-message",attrs:{"type":"is-success"}},[_vm._v(" Não há parâmetros cadastrados. Caso o usuário faça a requisição, apenas o número do contato será enviado para a requisição. ")]),_c('p')],1)],1)],1),_c('footer',{staticClass:"card-footer"},[_c('p',{staticClass:"card-footer-item"},[_c('b-button',{attrs:{"type":"is-warning"},on:{"click":function($event){return _vm.openUpdateIntegration(integration)}}},[_vm._v("Editar")])],1)])])}),0),(_vm.fetchingIntegrations)?_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.fetchingIntegrations),callback:function ($$v) {_vm.fetchingIntegrations=$$v},expression:"fetchingIntegrations"}}):_vm._e(),(_vm.isComponentModalActive)?_c('ModalInsertSystemIntegration',{attrs:{"isComponentModalActive":_vm.isComponentModalActive,"integrationUpdating":_vm.integrationUpdating},on:{"addDepartment":_vm.handleAddIntegration,"closeModal":_vm.closeModal,"updateIntegration":_vm.handleUpdateIntegration,"addIntegration":_vm.handleAddIntegration}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }