<template>
  <div>
    <b-tabs class="tabs-settings">
      <b-tab-item label="Gerais" icon="cog">
        <div class="row columns is-multiline is-vcentered">
          <div class="column is-one-third">
            <b-field label="Telefone">
              <b-input v-model="localSettings.phone" :disabled="true" expanded></b-input>
            </b-field>
          </div>
          <div class="column is-one-third">
            <b-field label="Apelido">
              <b-input v-model="localSettings.phoneName" expanded></b-input>
            </b-field>
          </div>
          <div class="column is-one-third">
            <b-field label="Cor do chat">
              <b-input type="text" v-model="localSettings.color" expanded />
              <b-input type="color" :style="{ backgroundColor: localSettings.color }" class="phone-color"
                v-model="localSettings.color" />
            </b-field>
          </div>
        </div>
        <b-field label="Intervalo entre envio das mensagens agendadas (em segundos)">
          <b-input type="number" min="20" v-model="localSettings.intervalFetchMessages"></b-input>
        </b-field>
        <b-field label="Intervalo limite para resposta da mensagem agendada redirecionar o contato (em minutos)">
          <b-input v-model="localSettings.responseTimeoutMinutes" min="1" type="number">
          </b-input>
        </b-field>
        <b-field label="No envio de uma mensagem agendada:">
          <b-select placeholder="Selecione uma opção" expanded v-model="localSettings.scheduledMessageWaitingTicketToClose" v-on:change.native="disableInputs">
            <option value="NON" key="NON">
              Enviar sempre mensagem agendada
            </option>
            <option value="WAI" key="WAI">Aguardar encerramento do ticket para enviar mensagem agendada</option>
          </b-select>
        </b-field>
        <b-field label="Intervalo limite para cancelar mensagem agendada aguardando fechamento do ticket (em minutos)">
          <b-input :disabled="disableScheduleMessageWaitingTimeout" v-model="localSettings.scheduledMessageWaitingTimeout" min="10" type="number">
          </b-input>
        </b-field>
        <b-message class="warning-message" type="is-success">
            Cada mensagem padrão suporta variáveis que, ao serem utilizadas, serão automaticamente substituídas pelos seus respectivos títulos. Sendo elas: 
            <br><br>
            • <b>{nome}:</b> Será substituída pelo nome do contato salvo na Zeeps. (O contato precisa estar salvo)
            <br>
            • <b>{nome_whatsapp}:</b> Será substituída pelo nome do contato no WhatsApp.
            <br>
            • <b>{telefone}:</b> Será substituída pelo número de telefone do contato.
            <br>
            • <b>{ticket}:</b> Será substituída pelo número de atendimento.
            <br>
            • <b>{novamensagem}:</b> Irá dividir e criar uma nova caixa de mensagem no WhatsApp.
        </b-message>
        <b-field>
          <template #label>
            Mensagem padrão novo chat (bem vindo)
            <b-tooltip type="is-dark" size="is-large" multilined>
              <template v-slot:content>
                É a mensagem inicial que o contato irá receber após ter acionado o bot
              </template>
              <b-icon type ="is-primary" size="is-small" icon="help-circle"></b-icon>
            </b-tooltip>
          </template>
          <editor api-key="no-api-key" v-model="localSettings.messageStartTicket" output-format="text" :init="{
            height: 200,
            menubar: false,
            plugins: ['emoticons'],
            toolbar: 'emoticons',
            branding: false,
            statusbar: false,
          }" />
        </b-field>
        <b-field>
          <template #label>
            Mensagem padrão encerramento ticket
            <b-tooltip type="is-dark" size="is-large" multilined>
                <template v-slot:content>
                   É a mensagem final que será enviada aos contatos após a resolução de seu ticket ou quando o contato digitar 'sair' para interromper a interação com o bot
                </template>
              <b-icon type ="is-primary" size="is-small" icon="help-circle"></b-icon>
            </b-tooltip>
          </template>
          <editor api-key="no-api-key" v-model="localSettings.messageEndTicket" output-format="text" :init="{
            height: 200,
            menubar: false,
            plugins: ['emoticons'],
            toolbar: 'emoticons',
            branding: false,
            statusbar: false,
          }" />
        </b-field>
        <b-field>
          <template #label>
            Mensagem padrão seleção de menu
            <b-tooltip type="is-dark" size="is-large" multilined>
                  <template v-slot:content>
                    É a mensagem enviada junto as opções de menu solicitando ao contato a seleção de um dos números correspondentes da opção de menu <br> (Opcional)
                  </template>
               <b-icon type ="is-primary" size="is-small" icon="help-circle"></b-icon>
            </b-tooltip>
          </template>
          <editor api-key="no-api-key" v-model="localSettings.customMessageMenu" output-format="text" :init="{
            height: 200,
            menubar: false,
            plugins: ['emoticons'],
            toolbar: 'emoticons',
            branding: false,
            statusbar: false,
          }" />
        </b-field>
        <b-field>
          <template #label>
            Mensgem padrão de resposta inválida no menu
            <b-tooltip type="is-dark" size="is-large" multilined>
               <template v-slot:content>
                    É a mensagem enviada quando o contato digita uma mensagem que não é correspondente a nenhum número das opções de menu <br> (Opcional)
                </template>
              <b-icon type ="is-primary" size="is-small" icon="help-circle"></b-icon>
            </b-tooltip>
          </template>
          <editor api-key="no-api-key" v-model="localSettings.invalidOptionWarningMessage" output-format="text" :init="{
            height: 200,
            menubar: false,
            plugins: ['emoticons'],
            toolbar: 'emoticons',
            branding: false,
            statusbar: false,
          }" />
        </b-field>
        <b-field> 
          <template #label>
            Mensagem padrão para processar requisição
            <b-tooltip type="is-dark" size="is-large" multilined>
               <template v-slot:content>
                    É a mensagem enviada no final da integração vinculada a opção de menu informando ao contato que a requisição dele está sendo processada. <br> (Opcional)
                </template>
              <b-icon type ="is-primary" size="is-small" icon="help-circle"></b-icon>
            </b-tooltip>
          </template>
          <editor api-key="no-api-key" v-model="localSettings.messageProcessingIntegration" output-format="text" :init="{
            height: 200,
            menubar: false,
            plugins: ['emoticons'],
            toolbar: 'emoticons',
            branding: false,
            statusbar: false,
          }" />
        </b-field>
      </b-tab-item>
      <b-tab-item label="Horário de Func." icon="clock">
        <b-field label="Horário de funcionamento (o horário de funcionamento padrão é de 24h/dia)">
          <b-select placeholder="Selecione um horário" v-model="localSettings.businessHoursId" expanded>
            <option :value="null"></option>
            <option v-for="bh in businessHours" :value="bh.id" :key="bh.id">
              {{ bh.description }}
            </option>
          </b-select>
        </b-field>
        <b-field>
          <template #label>
            <span>Quantidades de vezes que a mensagem de fora do horário de funcionamento será enviada </span>
            <b-tooltip type="is-dark" append-to-body multilined label="Define um limite de vezes que a mensagem será enviada para o contato. Informe 0 para a mensagem sempre ser enviada.">
              <b-icon type ="is-primary" size="is-small" icon="help-circle"></b-icon>
            </b-tooltip>
          </template>
          <b-input v-model="localSettings.sendOutOfBusinessMessageCount" min="0" type="number">
          </b-input>
        </b-field>
        <b-message class="warning-message" type="is-warning">
          A mensagem padrão será enviada quando o telefone e os departamentos das opções de menu estiverem fora do
          horário de funcionamento. Ou seja, se houverem opções de menu informativas, que atribuem tags ou que mostram
          menus, a mensagem não será enviada, pois esses menus serão.
        </b-message>
        <b-field label="Mensagem padrão enviada fora do horário de funcionamento">
          <editor api-key="no-api-key" v-model="localSettings.messageOutOfBusinessHours" output-format="text" :init="{
            height: 200,
            menubar: false,
            plugins: ['emoticons'],
            toolbar: 'emoticons',
            branding: false,
            statusbar: false,
          }" />
        </b-field>
      </b-tab-item>
      <b-tab-item label="Transf. Automática" icon="forward">
        <div class="row columns is-multiline is-vcentered">
          <div class="column is-one-third">
            <b-field label="Transferir ticket automaticamente para:">
              <b-select placeholder="Selecione uma opção" expanded v-model="localSettings.automaticTicketTransferType"
                v-on:change.native="disableInputs">
                <option value="TDP" key="TDP">Departamento</option>
                <option value="TAT" key="TAT">Atendente</option>
                <option value="NON" key="NON">
                  Transferência automática desativada
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-one-third">
            <b-field label="Departamento">
              <b-select expanded v-model="localSettings.departmentIdTransferTicketTo" :disabled="disableDepartmentsInput"
                v-on:change.native="setAttendants">
                <option v-for="department in departments" :value="department.id" :key="department.name">
                  {{ department.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-one-third">
            <b-field label="Atendente">
              <b-select expanded v-model="localSettings.attendantIdTransferTicketTo" :disabled="disableAttendantsInput">
                <option :value="null" :key="null"></option>
                <option v-for="attendant in attendants" :value="attendant.id" :key="attendant.name">
                  {{ attendant.name }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
        <b-field label="Tempo de espera para transferir o ticket (em minutos)">
          <b-input v-model="localSettings.intervalTransferTicketTo" :disabled="disableAll" min="1" type="number">
          </b-input>
        </b-field>
        <b-field label="Mensagem enviada após transferência">
          <editor api-key="no-api-key" v-model="localSettings.automaticTicketTransferMessage" :disabled="disableAll"
            output-format="text" :init="{
              height: 200,
              menubar: false,
              plugins: ['emoticons'],
              toolbar: 'emoticons',
              branding: false,
              statusbar: false,
            }" />
        </b-field>
      </b-tab-item>
      <b-tab-item label="Atendimento" icon="account-clock">
        <div class="row columns is-multiline is-vcentered">
          <div class="column">
            <b-field label="O que fazer quando não houverem atendentes disponíveis?">
              <b-select placeholder="Selecione uma opção" expanded v-model="localSettings.unavailableAttendantsAction"
                v-on:change.native="disableInputs">
                <option value="NON">Sempre aguardar atendimento</option>
                <option value="ASK">Perguntar se deseja aguardar atendimento</option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Tempo para envio da mensagem de aviso (em minutos)">
              <b-input v-model="localSettings.unavailableAttendantsWarningTime"
                :disabled="disableUnavailableAttendantsWarningTime" min="1" type="number" />
            </b-field>
          </div>
        </div>
        <div class="row columns is-multiline is-vcentered">
          <div class="column">
            <b-field label="Quantas vezes perguntar se deseja aguardar?">
              <b-input v-model="localSettings.unavailableAttendantsWarningCount"
                :disabled="disableUnavailableAttendantsWarningCount" min="1" type="number" />
            </b-field>
          </div>
          <div class="column">
            <b-field label="Ao atingir limite de perguntas:">
              <b-select expanded v-model="localSettings.unavailableAttendantsType"
                v-on:change.native="disableInputs" :disabled="disableUnavailableAttendantsType">
                <option key="CLOSE" value="CLOSE">Encerrar ticket</option>
                <option key="REPEAT" value="REPEAT">Enviar último menu selecionado pelo contato</option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Motivo de fechamento utilizado no encerramento">
              <b-select expanded v-model="localSettings.unavailableAttendantsCloseTicketReasonId"
                v-on:change.native="disableInputs" :disabled="disableUnavailableAttendantsCloseTicketReasonId">
                <option v-for="ticketCloseReason in ticketCloseReasons" :value="ticketCloseReason.id"
                  :key="ticketCloseReason.title">
                  {{ ticketCloseReason.title }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
        <b-message class="warning-message" type="is-warning">
          A <b>Mensagem de aviso sobre indisponibilidade de atendentes com questionamento</b> é enviada ao cliente e
          espera por
          resposta do tipo "sim" ou "não".
          <b>Sugestão:</b> No momento nossos atendentes estão indisponíveis. Deseja aguardar?
        </b-message>
        <b-field label="Mensagem de aviso sobre indisponibilidade de atendentes com questionamento">
          <editor api-key="no-api-key" v-model="localSettings.waitForAttendantQuestion"
            :disabled="disableWaitForAttendantQuestion" v-on:change.native="disableInputs" output-format="text" :init="{
              height: 200,
              menubar: false,
              plugins: ['emoticons'],
              toolbar: 'emoticons',
              branding: false,
              statusbar: false,
            }" />
        </b-field>
        <b-message class="warning-message" type="is-warning">
          A <b>Mensagem de aviso sobre indisponibilidade de atendentes com encerramento</b> é enviada ao cliente antes
          do encerramento do ticket por indisponibilidade de atendimento.
          <b>Sugestão:</b> Sentimos muito pela espera, mas nossos atendentes não estão disponíveis no momento. Por favor,
          tente novamente mais tarde
        </b-message>
        <b-field label="Mensagem de aviso sobre indisponibilidade de atendentes">
          <editor api-key="no-api-key" v-model="localSettings.unavailableAttendantsCloseTicketMessage"
            :disabled="disableUnavailableAttendantsCloseTicketMessage" v-on:change.native="disableInputs"
            output-format="text" :init="{
              height: 200,
              menubar: false,
              plugins: ['emoticons'],
              toolbar: 'emoticons',
              branding: false,
              statusbar: false,
            }" />
        </b-field>
      </b-tab-item>
      <b-tab-item label="Fechamento Automático" icon="message-text-clock">
        <div class="row columns is-multiline is-vcentered">
          <div class="column is-one-third">
            <b-field label="Realizar o fechamento do ticket automaticamente?">
              <b-select placeholder="Selecione uma opção" expanded v-model="localSettings.ticketAutomaticCloseType"
                v-on:change.native="disableInputs">
                <option value="NON">Não fechar o ticket automaticamente</option>
                <option value="WAR">
                  Enviar mensagem de aviso antes de fechamento automático dos tickets
                </option>
                <option value="CLO">
                  Somente fechar tickets automaticamente
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-one-third">
            <b-field label="Tempo até o fechamento do ticket (em minutos)">
              <b-input v-model="localSettings.ticketAutomaticCloseTime" v-on:change.native="disableInputs"
                :disabled="disableTicketAutomaticCloseTime" min="1" type="number">
              </b-input>
            </b-field>
          </div>
          <div class="column is-one-third">
            <b-field label="Motivo de fechamento utilizado">
              <b-select expanded v-model="localSettings.ticketAutomaticCloseReasonId" v-on:change.native="disableInputs"
                :disabled="disableTicketAutomaticCloseReasonId">
                <option v-for="ticketCloseReason in ticketCloseReasons" :value="ticketCloseReason.id"
                  :key="ticketCloseReason.title">
                  {{ ticketCloseReason.title }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
        <b-field label="Tempo para envio da mensagem de aviso de fechamento (em minutos)">
          <b-input v-model="localSettings.ticketAutomaticCloseWarningTime" v-on:change.native="disableInputs"
            :disabled="disableTicketAutomaticCloseWarningTime" min="1" type="number">
          </b-input>
        </b-field>
        <b-field label="Mensagem de aviso do fechamento (enviada antes do fechamento)">
          <editor api-key="no-api-key" v-model="localSettings.ticketAutomaticCloseWarningMessage"
            :disabled="disableTicketAutomaticCloseWarningMessage" v-on:change.native="disableInputs" output-format="text"
            :init="{
              height: 200,
              menubar: false,
              plugins: ['emoticons'],
              toolbar: 'emoticons',
              branding: false,
              statusbar: false,
            }" />
        </b-field>
      </b-tab-item>
      <b-tab-item label="Pesq. Satisfação" icon="clipboard-edit">
        <b-field label="Mensagem padrão de início da pesquisa de satisfação">
          <b-input v-model="localSettings.messageStartSurvey"></b-input>
        </b-field>
        <b-field label="Mensagem padrão de encerramento da pesquisa de satisfação">
          <b-input v-model="localSettings.messageEndSurvey"></b-input>
        </b-field>
        <b-field label="Duração (Por quanto tempo, em minutos, esperar pela resposta?)">
          <b-input v-model="localSettings.intervalFinishSurvey" type="number" min="1" required expanded />
        </b-field>
        <b-field label="Mensagem padrão de encerramento da pesquisa de satisfação por falta de resposta">
          <b-input v-model="localSettings.messageCancelSurvey"></b-input>
        </b-field>
        <b-message class="warning-message" type="is-warning">
          A mensagem padrão para resposta inválida será enviada quando o contato responder a pesquisa com uma mensagem que
          não seja um número de 0 a 10.
        </b-message>
        <b-field label="Mensagem padrão para resposta inválida">
          <b-input v-model="localSettings.messageInvalidAnswer"></b-input>
        </b-field>
        <div class="field">
          <b-checkbox v-model="localSettings.resendSurveyQuestion">Reenviar pergunta da pesquisa em caso de resposta
            inválida</b-checkbox>
        </div>
      </b-tab-item>
      <b-tab-item label="Contatos" icon="account-box">
        <div class="row columns is-multiline is-vcentered">
          <div class="column">
            <b-field label="Salvar contatos automaticamente">
              <b-checkbox v-model="localSettings.autoSaveContacts">Salvar contatos automaticamente quando novos
                clientes
                entrarem em contato</b-checkbox>
            </b-field>
            <b-field label="Tipo de restrição de contatos:">
              <b-select placeholder="Selecione uma opção" expanded v-model="localSettings.contactsRestrictionsType"
                v-on:change.native="disableInputs">
                <option value="REQ" key="REQ">
                  Exigir informações dos contatos e aprovar o acesso
                  automaticamente
                </option>
                <option value="RIT" key="RIT">Exigir informações e integrar com sistema externo
                </option>
                <option value="NON" key="NON">
                  Restrição de contatos desativada
                </option>
              </b-select>
            </b-field>
            <b-field label="Mensagem customizada para requisição dos dados do contato">
              <b-input v-model="localSettings.contactsRestrictionWelcomeMessage"
                :disabled="localSettings.contactsRestrictionsType === 'NON'"></b-input>
            </b-field>
            <b-field label="Perguntas customizadas para cadatro/validação do contato">
              <ContactsFieldsQuesitonsList :questions="questions" />
            </b-field>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="Privacidade" icon="account-box">
        <div class="row columns is-multiline is-vcentered">
          <div class="column">
            <b-field label="Termos de privacidade">
              <b-checkbox v-model="localSettings.requestAprovalPrivacyTerms" v-on:change.native="disableInputs">
                Exigir
                aceite de termos de privacidade</b-checkbox>
            </b-field>

            <b-field label="Termos de privacidade">
              <b-select placeholder="Selecione uma opção" expanded v-model="localSettings.privacyTermsRequestType"
                :disabled="disablePrivacyTermns">
                <option value="DEF" key="DEF">
                  Exigir apenas uma vez e salvar no contato
                </option>
                <option value="ALL" key="ALL">Sempre exigir quando o contato abrir um novo ticket
                </option>
              </b-select>
            </b-field>
            <b-field
              label="Mensagem requisição de aceite dos termos de privacidade (deverá conter o link para os termos de privacidade)">
              <b-input v-model="localSettings.privacyTermsMessage" :disabled="disablePrivacyTermns"></b-input>
            </b-field>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
    <div class="level-right">
      <button @click="saveSettings" class="button is-success mt-2 mb-2" slot="level-right">
        Salvar configurações
      </button>
    </div>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import ContactsFieldsQuesitonsList from '@/components/settings/ContactsFieldsQuesitonsList.vue'
import { mapActions, mapGetters } from 'vuex'

const UNAVAILABLE_ATTENDANTS_TYPE = {
  CLOSE: 'CLOSE',
  REPEAT: 'REPEAT'
}

const UNAVAILABLE_ATTENDANTS_ACTION = {
  NON: 'NON',
  ASK: 'ASK'
}

export default {
  name: 'Configs',
  props: {
    settings: {
      type: Object,
      required: true
    },
    modal: {
      type: Boolean,
      required: true
    },
    allAttendants: {
      type: Array,
      required: true
    },
    businessHours: {
      type: Array,
      required: true
    },
    ticketCloseReasons: {
      type: Array,
      required: true
    },
    questions: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(['allDepartments'])
  },
  data () {
    return {
      localSettings: this.settings,
      disableAttendantsInput: true,
      disableDepartmentsInput: true,
      disableAll: true,
      disableRestrictionWelcomeMessage: true,
      disablePrivacyTermns: true,
      attendants: [],
      departments: [],
      disableTicketAutomaticCloseReasonId: true,
      disableTicketAutomaticCloseTime: true,
      disableTicketAutomaticCloseWarningMessage: true,
      disableTicketAutomaticCloseWarningTime: true,
      disableUnavailableAttendantsWarningTime: true,
      disableUnavailableAttendantsWarningCount: true,
      disableWaitForAttendantQuestion: true,
      disableUnavailableAttendantsCloseTicketMessage: true,
      disableUnavailableAttendantsCloseTicketReasonId: true,
      disableUnavailableAttendantsType: true,
      disableScheduleMessageWaitingTimeout: true
    }
  },
  components: {
    Editor,
    ContactsFieldsQuesitonsList
  },
  created () {
    this.fetchDepartments(this.settings.phone).then(() => this.departments = this.allDepartments)
    this.setSettings(this.settings)
    this.disableInputs()
  },
  methods: {
    ...mapActions(['fetchDepartments']),
    disableInputs () {
      if (this.localSettings.automaticTicketTransferType === 'NON') {
        this.disableAttendantsInput = true
        this.disableDepartmentsInput = true
        this.disableAll = true
        this.localSettings.departmentIdTransferTicketTo = null
        this.localSettings.attendantIdTransferTicketTo = null
        this.localSettings.intervalTransferTicketTo =
          this.localSettings.interval_transfer_ticket_to
        this.localSettings.automaticTicketTransferMessage = ''
      } else if (this.localSettings.automaticTicketTransferType === 'TDP') {
        this.disableAttendantsInput = true
        this.disableDepartmentsInput = false
        this.disableAll = false
        this.localSettings.attendantIdTransferTicketTo = null
      } else if (this.localSettings.automaticTicketTransferType === 'TAT') {
        this.disableDepartmentsInput = false
        if (this.localSettings.departmentIdTransferTicketTo) {
          this.setAttendants()
        } else {
          this.disableAttendantsInput = true
        }
        this.disableAll = false
      }

      if (this.localSettings.unavailableAttendantsAction === UNAVAILABLE_ATTENDANTS_ACTION.NON) {
        this.disableUnavailableAttendantsWarningTime = true
        this.disableUnavailableAttendantsWarningCount = true
        this.disableWaitForAttendantQuestion = true
        this.disableUnavailableAttendantsCloseTicketMessage = true
        this.disableUnavailableAttendantsCloseTicketReasonId = true
        this.disableUnavailableAttendantsType = true
        this.localSettings.unavailableAttendantsWarningTime = null
        this.localSettings.unavailableAttendantsWarningCount = null
        this.localSettings.waitForAttendantQuestion = ''
        this.localSettings.unavailableAttendantsCloseTicketMessage = ''
        this.localSettings.unavailableAttendantsCloseTicketReasonId = null
      } else if (this.localSettings.unavailableAttendantsAction === UNAVAILABLE_ATTENDANTS_ACTION.ASK) {
        this.disableUnavailableAttendantsWarningTime = false
        this.disableUnavailableAttendantsWarningCount = false
        this.disableWaitForAttendantQuestion = false
        this.disableUnavailableAttendantsCloseTicketMessage = false
        this.disableUnavailableAttendantsType = false
      }

      if (this.localSettings.unavailableAttendantsType === UNAVAILABLE_ATTENDANTS_TYPE.CLOSE && this.localSettings.unavailableAttendantsAction === UNAVAILABLE_ATTENDANTS_ACTION.ASK) {
        this.disableUnavailableAttendantsCloseTicketReasonId = false
      } else {
        this.disableUnavailableAttendantsCloseTicketReasonId = true
        this.localSettings.unavailableAttendantsCloseTicketReasonId = null
      }

      if (this.localSettings.ticketAutomaticCloseType === 'NON') {
        this.disableTicketAutomaticCloseReasonId = true
        this.disableTicketAutomaticCloseTime = true
        this.disableTicketAutomaticCloseWarningMessage = true
        this.disableTicketAutomaticCloseWarningTime = true
        this.localSettings.ticketAutomaticCloseReasonId = null
        this.localSettings.ticketAutomaticCloseTime = null
        this.localSettings.ticketAutomaticCloseWarningMessage = ''
        this.localSettings.ticketAutomaticCloseWarningTime = null
      } else if (this.localSettings.ticketAutomaticCloseType === 'CLO') {
        this.disableTicketAutomaticCloseReasonId = false
        this.disableTicketAutomaticCloseTime = false
        this.disableTicketAutomaticCloseWarningMessage = true
        this.disableTicketAutomaticCloseWarningTime = true
        this.localSettings.ticketAutomaticCloseWarningMessage = ''
        this.localSettings.ticketAutomaticCloseWarningTime = null
      } else if (this.localSettings.ticketAutomaticCloseType === 'WAR') {
        this.disableTicketAutomaticCloseReasonId = false
        this.disableTicketAutomaticCloseTime = false
        this.disableTicketAutomaticCloseWarningMessage = false
        this.disableTicketAutomaticCloseWarningTime = false
      }

      if (this.localSettings.contactsRestrictionsType !== 'REQ') {
        this.disableRestrictionWelcomeMessage = true
      } else {
        this.disableRestrictionWelcomeMessage = false
      }

      if (this.localSettings.requestAprovalPrivacyTerms) {
        this.disablePrivacyTermns = false
      } else {
        this.disablePrivacyTermns = true
      }

      if (this.localSettings.scheduledMessageWaitingTicketToClose === 'NON') {
        this.disableScheduleMessageWaitingTimeout = true
      } else {
        this.disableScheduleMessageWaitingTimeout = false
      }
    },
    setAttendants () {
      if (this.localSettings.automaticTicketTransferType !== 'TAT') return

      this.disableAttendantsInput = false
      this.attendants = this.allAttendants.filter(att => {
        if (att.departments.find(dpt => dpt.id === this.localSettings.departmentIdTransferTicketTo) || att.supervisor_all_departments) {
          return att
        }
      })
      if (!this.attendants.find(att => att.id === this.localSettings.attendantIdTransferTicketTo)) {
        this.localSettings.attendantIdTransferTicketTo = null
      }
    },
    setSettings (settings) {
      const config = settings
      config.phoneName = config.name
      config.messageStartTicket = config.message_start_ticket ? config.message_start_ticket.replaceAll('\n', '<br>') : ''
      config.messageEndTicket = config.message_end_ticket ? config.message_end_ticket.replaceAll('\n', '<br>') : ''
      config.customMessageMenu = config.custom_message_menu ? config.custom_message_menu.replaceAll('\n', '<br>') : ''
      config.messageProcessingIntegration = config.message_processing_integration ? config.message_processing_integration.replaceAll('\n', '<br>') : ''
      config.invalidOptionWarningMessage = config.invalid_option_warning_message ? config.invalid_option_warning_message.replaceAll('\n', '<br>'): ''
      config.intervalFetchMessages = config.interval_fetch_messages || 30
      config.responseTimeoutMinutes = config.response_timeout_minutes || 30
      config.isComponentModalActive = config.modal
      config.automaticTicketTransferType =
        config.automatic_ticket_transfer_type || 'NON'
      config.departmentIdTransferTicketTo =
        config.department_id_transfer_ticket_to || null
      config.attendantIdTransferTicketTo =
        config.attendant_id_transfer_ticket_to || null
      config.intervalTransferTicketTo =
        config.interval_transfer_ticket_to || null
      config.automaticTicketTransferMessage = config.automatic_ticket_transfer_message ? config.automatic_ticket_transfer_message.replaceAll('\n', '<br>') : ''
      config.requestAprovalPrivacyTerms = config.request_approval_privacy_terms
      config.privacyTermsMessage = config.privacy_terms_message || ''
      config.autoSaveContacts = config.auto_save_contacts || false
      config.disableAttendantsInput = true
      config.disableDepartmentsInput = true
      config.disableAll = true
      config.contactsRestrictionsType =
        config.contacts_restrictions_type || 'NON'
      config.contactsRestrictionWelcomeMessage =
        config.contacts_restrictions_welcome_message || ''
      config.businessHoursId = config.business_hours_id
      config.messageOutOfBusinessHours = config.message_out_of_business_hours ? config.message_out_of_business_hours.replaceAll('\n', '<br>') : ''
      config.messageStartSurvey = config.message_start_survey ? config.message_start_survey.replaceAll('\n', '<br>') : ''
      config.messageInvalidAnswer = config.message_invalid_answer ? config.message_invalid_answer.replaceAll('\n', '<br>') : ''
      config.messageEndSurvey = config.message_end_survey ? config.message_end_survey.replaceAll('<br>', '\n') : ''
      config.messageCancelSurvey = config.message_cancel_survey ? config.message_cancel_survey.replaceAll('<br>', '\n') : ''
      config.resendSurveyQuestion = config.resend_survey_question ?? false
      config.intervalFinishSurvey = config.interval_finish_survey || 10
      config.ticketAutomaticCloseType = config.ticket_automatic_close_type || 'NON'
      config.ticketAutomaticCloseWarningMessage = config.ticket_automatic_close_warning_message || ''
      config.ticketAutomaticCloseWarningTime = config.ticket_automatic_close_warning_time || 30
      config.ticketAutomaticCloseTime = config.ticket_automatic_close_time || 45
      config.ticketAutomaticCloseReasonId = config.ticket_automatic_close_reason_id
      config.unavailableAttendantsAction = config.unavailable_attendants_action || UNAVAILABLE_ATTENDANTS_ACTION.NON
      config.unavailableAttendantsWarningTime = config.unavailable_attendants_warning_time || 10
      config.unavailableAttendantsWarningCount = config.unavailable_attendants_warning_count || 1
      config.unavailableAttendantsCloseTicketReasonId = config.unavailable_attendants_close_ticket_reason_id
      config.waitForAttendantQuestion = config.wait_for_attendant_question || ''
      config.unavailableAttendantsCloseTicketMessage = config.unavailable_attendants_close_ticket_message || ''
      config.privacyTermsRequestType = config.privacy_terms_request_type || 'DEF'
      config.scheduledMessageWaitingTimeout = config.scheduled_message_waiting_timeout || 1440
      config.sendOutOfBusinessMessageCount = config.send_out_of_business_message_count || 0
      config.scheduledMessageWaitingTicketToClose = config.scheduled_message_waiting_ticket_to_close || 'NON'
      config.unavailableAttendantsType = config.unavailable_attendants_type || UNAVAILABLE_ATTENDANTS_TYPE.CLOSE

      this.localSettings = config
    },
    saveSettings () {
      const config = this.settings
      config.name = config.phoneName
      config.message_start_ticket = config.messageStartTicket ? config.messageStartTicket?.replaceAll('<br>', '\n') : ''
      config.message_end_ticket = config.messageEndTicket ? config.messageEndTicket.replaceAll('<br>', '\n') : ''
      config.custom_message_menu = config.customMessageMenu ? config.customMessageMenu.replaceAll('<br>', '\n') : ''
      config.message_processing_integration = config.messageProcessingIntegration ? config.messageProcessingIntegration.replaceAll('<br>', '\n') : ''
      config.invalid_option_warning_message =  config.invalidOptionWarningMessage ? config.invalidOptionWarningMessage.replaceAll('<br>', '\n'): ''
      config.interval_fetch_messages = config.intervalFetchMessages
      config.response_timeout_minutes = config.responseTimeoutMinutes
      config.automatic_ticket_transfer_type = config.automaticTicketTransferType
      config.department_id_transfer_ticket_to = config.departmentIdTransferTicketTo
      config.attendant_id_transfer_ticket_to = config.attendantIdTransferTicketTo
        ? config.attendantIdTransferTicketTo
        : null
      config.interval_transfer_ticket_to = config.intervalTransferTicketTo
      config.automatic_ticket_transfer_message = config.automaticTicketTransferMessage ? config.automaticTicketTransferMessage.replaceAll('<br>', '\n') : ''
      config.contacts_restrictions_type = config.contactsRestrictionsType
      config.contacts_restrictions_welcome_message = config.contactsRestrictionWelcomeMessage
      config.request_approval_privacy_terms = config.requestAprovalPrivacyTerms
      config.privacy_terms_message = config.privacyTermsMessage
      config.auto_save_contacts = config.autoSaveContacts
      config.business_hours_id = config.businessHoursId
      config.message_out_of_business_hours = config.messageOutOfBusinessHours ? config.messageOutOfBusinessHours.replaceAll('<br>', '\n') : ''
      config.color = config.color ?? ''
      config.message_start_survey = config.messageStartSurvey ? config.messageStartSurvey.replaceAll('<br>', '\n') : ''
      config.message_end_survey = config.messageEndSurvey ? config.messageEndSurvey.replaceAll('<br>', '\n') : ''
      config.message_cancel_survey = config.messageCancelSurvey ? config.messageCancelSurvey.replaceAll('<br>', '\n') : ''
      config.message_invalid_answer = config.messageInvalidAnswer ? config.messageInvalidAnswer.replaceAll('<br>', '\n') : ''
      config.resend_survey_question = config.resendSurveyQuestion ?? false
      config.interval_finish_survey = config.intervalFinishSurvey
      config.ticket_automatic_close_type = config.ticketAutomaticCloseType
      config.ticket_automatic_close_warning_message = config.ticketAutomaticCloseWarningMessage
      config.ticket_automatic_close_warning_time = config.ticketAutomaticCloseWarningTime
      config.ticket_automatic_close_time = config.ticketAutomaticCloseTime
      config.ticket_automatic_close_reason_id = config.ticketAutomaticCloseReasonId
      config.unavailable_attendants_action = config.unavailableAttendantsAction === 'WAI' ? UNAVAILABLE_ATTENDANTS_ACTION.NON : config.unavailableAttendantsAction
      config.unavailable_attendants_warning_time = config.unavailableAttendantsWarningTime
      config.unavailable_attendants_warning_count = config.unavailableAttendantsWarningCount
      config.unavailable_attendants_close_ticket_reason_id = config.unavailableAttendantsCloseTicketReasonId
      config.wait_for_attendant_question = config.waitForAttendantQuestion
      config.unavailable_attendants_close_ticket_message = config.unavailableAttendantsCloseTicketMessage
      config.privacy_terms_request_type = config.privacyTermsRequestType
      config.scheduled_message_waiting_timeout = config.scheduledMessageWaitingTimeout
      config.send_out_of_business_message_count = config.sendOutOfBusinessMessageCount
      config.scheduled_message_waiting_ticket_to_close = config.scheduledMessageWaitingTicketToClose
      config.unavailable_attendants_type = config.unavailableAttendantsType

      if (config.intervalFetchMessages < 20) {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Evite ter seu <b>número banido</b>! O intervalo para envio de mensagens agendadas está muito baixo.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return
      }

      const attendantId = this.attendants.find(attendant => attendant.id === config.attendantIdTransferTicketTo)
      const departmentId = this.departments.find(department => department.id === config.departmentIdTransferTicketTo)

      if (config.automaticTicketTransferType === 'TAT' && (!attendantId || !departmentId)) {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'É necessário informar um atendente e um departamento para a transferência automática.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return
      }

      if (config.automaticTicketTransferType === 'TDP' && (!departmentId)) {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'É necessário informar um departamento para a transferência automática.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return
      }

      if (config.ticketAutomaticCloseType === 'WAR' && !config.ticketAutomaticCloseWarningMessage) {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'É necessário informar uma mensagem de aviso de fechamento do Ticket.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return
      }

      if (config.ticketAutomaticCloseType === 'WAR' && !config.ticketAutomaticCloseWarningTime) {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'É necessário informar um tempo para envio do aviso de fechamento do ticket.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return
      }

      if (config.ticketAutomaticCloseType === 'WAR' && Number(config.ticketAutomaticCloseTime) <= Number(config.ticketAutomaticCloseWarningTime)) {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'É necessário informar um tempo para envio do aviso de fechamento do ticket menor que o tempo de fechamento.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return
      }

      if (config.unavailableAttendantsAction === UNAVAILABLE_ATTENDANTS_ACTION.ASK) {
        let errorMessage = ''
        if (!config.unavailableAttendantsWarningTime) {
          errorMessage = 'É necessário informar um tempo para envio do aviso de indisponibilidade de atendentes.'
        } else if (!config.unavailableAttendantsWarningCount) {
          errorMessage = 'É necessário informar quantas vezes deve perguntar se deseja aguardar um atendente.'
        } else if (!config.waitForAttendantQuestion || config.waitForAttendantQuestion === '') {
          errorMessage = 'É necessário informar uma mensagem de aviso de indisponibilidade de atendentes com questionamento.'
        } else if (config.unavailableAttendantsType === UNAVAILABLE_ATTENDANTS_TYPE.CLOSE && !config.unavailableAttendantsCloseTicketReasonId) {
          errorMessage = 'É necessário informar um motivo de fechamento para o encerramento devido a indisponibilidade de atendentes.'
        } else if (!config.unavailableAttendantsCloseTicketMessage || config.unavailableAttendantsCloseTicketMessage === '') {
          errorMessage = 'É necessário informar uma mensagem de aviso de indisponibilidade de atendentes com encerramento.'
        }

        if (errorMessage && errorMessage !== '') {
          this.$buefy.dialog.alert({
            title: 'Error',
            message: errorMessage,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          return
        }
      }

      if (config.requestAprovalPrivacyTerms && config.privacyTermsMessage.length === 0) {
        this.$buefy.dialog.alert({
          title: 'Erro',
          message: 'É necessário informar uma mensagem para solicitar o aceite dos termos de privacidade.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return
      }

      if (config.autoSaveContacts === false && config.requestAprovalPrivacyTerms === true) {
        this.$buefy.dialog.alert({
          title: 'Erro',
          message: 'É necessário habilitar a opção de salvar contatos automaticamente, na aba Contatos, para poder solicitar o aceite dos termos de privacidade.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return
      }

      if (config.scheduledMessageWaitingTicketToClose === 'WAI' && config.scheduledMessageWaitingTimeout === 0) {
        this.$buefy.dialog.alert({
          title: 'Erro',
          message: 'É necessário informar um tempo para fechamento do ticket ou para envio de uma nova mensagem.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return
      }

      this.$emit('saveSettings', {
        settings: this.localSettings
      })
    }
  },
  watch: {
    settings (newValue) {
      this.setSettings(newValue)
      this.disableInputs()
    }
  }
}
</script>
<style>
.tabs-settings .is-active a {
  border-bottom-color: #00A48D !important;
  color: #00A48D !important;
}

.tabs-settings .phone-color {
  border-radius: 5px;
  border: 0px
}

.tabs-settings .phone-color input[type="color"] {
  background: none;
}
</style>
