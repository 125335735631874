<template>
  <div>
    <section class="section is-main-section">
      <a
        class="button is-info mb-2"
        @click="() => this.$router.push('/integracoes')"
      >
        <b-icon icon="arrow-left" custom-size="default"></b-icon>
        <span>Lista de sistemas</span>
      </a>
      <a
        class="button is-success is-pulled-right mb-2"
        @click="() => this.openAddIntegration()"
      >
        <span>Adicionar integração</span>
        <b-icon icon="plus" custom-size="default"></b-icon>
      </a>
      <hr />
      <b-field label="ID">
        <b-input type="text" v-model="id" disabled required> </b-input>
      </b-field>
      <b-field label="Código customizado">
        <b-input type="text" v-model="custom_code" disabled required> </b-input>
      </b-field>
      <b-field label="Nome">
        <b-input type="text" v-model="name" disabled required> </b-input>
      </b-field>
      <b-field label="Integrações">
        <div
          class="card"
          v-for="integration of external_system_integrations"
          :key="integration.id"
        >
          <header class="card-header">
            <p class="card-header-title">{{ integration.id }} - {{ integration.name }}{{ integration.custom_code ? ` - (${integration.custom_code})` : '' }}</p>
          </header>
          <div class="card-content">
            <b-field>
              <b-checkbox :value="integration.send_menu" disabled>Enviar menu após completar requisição</b-checkbox>
            </b-field>
            <b-field label="Parâmetros ativos">
              <b-table
                v-if="
                  getFilteredParams(
                    integration.external_system_integration_params,
                    true,
                  ).length
                "
                :data="
                  getFilteredParams(
                    integration.external_system_integration_params,
                    true,
                  )
                "
                :columns="integrationsTableColumns"
              ></b-table>
              <div v-else>
                <b-message class="warning-message" type="is-success">
                  Não há parâmetros cadastrados. Caso o usuário faça a
                  requisição, apenas o número do contato será enviado para a
                  requisição.
                </b-message>
                <p></p>
              </div>
            </b-field>
          </div>
          <footer class="card-footer">
            <p class="card-footer-item">
              <b-button
                type="is-warning"
                @click="openUpdateIntegration(integration)"
                >Editar</b-button
              >
              <!-- <b-button type="is-danger ml-2">Excluir</b-button> -->
            </p>
          </footer>
        </div>
      </b-field>
      <b-loading
        v-if="fetchingIntegrations"
        :is-full-page="true"
        v-model="fetchingIntegrations"
        :can-cancel="false"
      ></b-loading>
      <ModalInsertSystemIntegration
        v-if="isComponentModalActive"
        v-on:addDepartment="handleAddIntegration"
        v-on:closeModal="closeModal"
        :isComponentModalActive="isComponentModalActive"
        :integrationUpdating="integrationUpdating"
        v-on:updateIntegration="handleUpdateIntegration"
        v-on:addIntegration="handleAddIntegration"
      />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from 'vuex'
import ModalInsertSystemIntegration from '../components/modals/ModalInsertSystemIntegration.vue'

export default {
  name: 'ExternalSystemsDetailsScreen',
  components: {
    ModalInsertSystemIntegration
  },
  data () {
    return {
      isComponentModalActive: false,
      id: '',
      name: '',
      custom_code: '',
      external_system_integrations: [],
      integrationUpdating: null,
      showParamsDisabled: false,
      integrationsTableColumns: [
        {
          field: 'id',
          label: 'ID',
          numeric: true
        },
        {
          field: 'exibition_name',
          label: 'Texto de exibição'
        },
        {
          field: 'custom_code',
          label: 'Código do campo'
        },
        {
          field: 'type',
          label: 'Tipo'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['allSystems', 'fetchingIntegrations']),
    titleStack () {
      return ['Configurações', 'Detalhes da integração']
    }
  },
  async created () {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchSystemDetail', 'updateIntegration', 'addIntegration']),
    handleAddIntegration (integration) {
      integration.external_system_id = this.id
      this.closeModal()
      this.addIntegration(integration)
        .then(() => this.fetchData())
        .catch((error) => {
          console.warn(error)
          this.$buefy.dialog.alert({
            title: 'Erro',
            message:
              'Ocorreu um <b>erro</b> ao atualizar os dados do sistema. Recarregue a tela e tente novamente!',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        })
    },
    async fetchData () {
      const systemData = await this.fetchSystemDetail(this.$route.params.id)
      this.id = systemData.id
      this.name = systemData.name
      this.custom_code = systemData.custom_code
      this.external_system_integrations =
        systemData.external_system_integrations
    },
    openModal () {
      this.isComponentModalActive = !this.isComponentModalActive
    },
    closeModal () {
      this.isComponentModalActive = false
    },
    openAddIntegration () {
      this.integrationUpdating = null
      this.openModal()
    },
    openUpdateIntegration (integration) {
      this.integrationUpdating = integration
      this.openModal()
    },
    getFilteredParams (params, enabled) {
      return params.filter(param => param.enabled === enabled)
    },
    handleUpdateIntegration (integration) {
      this.closeModal()
      this.updateIntegration(integration)
        .then(() => this.fetchData())
        .catch(() =>
          this.$buefy.dialog.alert({
            title: 'Erro',
            message:
              'Ocorreu um <b>erro</b> ao atualizar os dados do sistema. Recarregue a tela e tente novamente!',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        )
    }
  }
}
</script>
