<template>
  <div>
    <section>
      <b-modal
        :active="isComponentModalActive"
        @close="closeModal"
      >
        <div
          class="modal-card"
          style="width: auto"
        >
          <header class="modal-card-head">
            <p
              v-if="integrationUpdating"
              class="modal-card-title"
            >
              Atualizar informações da integração
            </p>
            <p
              v-else
              class="modal-card-title"
            >
              Insira o sistema a ser cadastrado
            </p>
          </header>
          <section class="modal-card-body">
            <b-field
              label="ID"
              v-if="!!id"
            >
              <b-input
                type="text"
                v-model="id"
                disabled
              > </b-input>
            </b-field>
            <b-field label="Código customizado">
              <b-input
                type="text"
                v-model="custom_code"
                placeholder="Código customizado"
              >
              </b-input>
            </b-field>
            <b-field label="Nome">
              <b-input
                type="text"
                v-model="name"
                placeholder="Nome"
                required
              >
              </b-input>
            </b-field>
            <b-field label="Parâmetros da integração">
              <div>
                <div class="row columns is-multiline is-vcentered">
                  <div class="column is-one-quarter">
                    <b-field
                      label="Texto de exibição"
                      label-position="on-border"
                    >
                      <b-input
                        expanded
                        type="search"
                        v-model="exibitionName"
                      >
                      </b-input>
                    </b-field>
                  </div>
                  <div class="column is-one-quarter">
                    <b-field
                      label="Código do campo"
                      label-position="on-border"
                    >
                      <b-input
                        expanded
                        type="search"
                        v-model="customCode"
                      >
                      </b-input>
                    </b-field>
                  </div>
                  <div class="column is-one-quarter">
                    <b-field
                      label="Tipo do campo"
                      label-position="on-border"
                    >
                      <b-select
                        expanded
                        placeholder="Selecione um departamento"
                        v-model="type"
                      >
                        <option value="string">string</option>
                        <option value="custom">custom</option>
                        <option value="checkpoint">checkpoint</option>
                      </b-select>
                    </b-field>
                  </div>
                  <div class="column is-one-quarter">
                    <p class="control">
                      <button
                        class="button is-primary is-fullwidth"
                        @click="addParam()"
                      >
                        Adicionar
                      </button>
                    </p>
                  </div>
                </div>
                <div>
                  <b-table
                    :data="getFilteredParams(integrationParams, true)"
                    :bordered="false"
                    :striped="false"
                    :narrowed="false"
                    :hoverable="true"
                    :loading="false"
                    :focusable="false"
                    :mobile-cards="true"
                    :draggable="true"
                    @dragstart="dragstart"
                    @drop="drop"
                    @dragover="dragover"
                    @dragleave="dragleave"
                    @mouseenter="mouseenter"
                    @mouseleave="mouseleave"
                  >
                    <b-table-column
                      >
                      <b-icon icon="dots-grid" />
                    </b-table-column>
                    <b-table-column
                      field="exibition_name"
                      label="Texto de exibição"
                      v-slot="props"
                    >
                      <input
                        class="input"
                        v-model="props.row.exibition_name"
                      />
                    </b-table-column>

                    <b-table-column
                      field="custom_code"
                      label="Código do campo"
                      v-slot="props"
                    >
                      <input
                        class="input"
                        v-model="props.row.custom_code"
                      />
                    </b-table-column>

                    <b-table-column
                      field="type"
                      label="Tipo"
                      v-slot="props"
                    >
                      <b-field expanded>
                        <b-select
                          expanded
                          placeholder="Selecione um tipo"
                          v-model="props.row.type"
                          @change.native="editLink"
                        >
                          <option value="string">string</option>
                          <option value="custom">custom</option>
                          <option value="checkpoint">checkpoint</option>
                        </b-select>
                      </b-field>
                    </b-table-column>

                    <b-table-column
                      field="status"
                      label="Status"
                    >
                      <b-tag type="is-success"> ATIVO </b-tag>
                    </b-table-column>

                    <b-table-column
                      v-if="!!integrationUpdating"
                      field="delete"
                      width="200"
                      label="Desativar/remover"
                      v-slot="props"
                    >
                      <b-button
                        v-if="!!props.row.id"
                        type="is-danger is-small is-fullwidth"
                        @click="changeParamEnabled(props.row, false)"
                      >Desativar</b-button>
                      <b-button
                        v-if="!props.row.id"
                        type="is-danger is-small is-fullwidth"
                        @click="deleteParam(props.row)"
                      >Remover</b-button>
                    </b-table-column>
                  </b-table>

                  <div v-if="!getFilteredParams(integrationParams, true).length">
                    <b-message
                      class="warning-message"
                      type="is-success"
                    >
                      Não há parâmetros cadastrados. Caso o usuário faça a
                      requisição, apenas o número do contato será enviado para a
                      requisição.
                    </b-message>
                    <p></p>
                  </div>

                  <span
                    class="has-text-centered is-block"
                    v-if="!!integrationUpdating"
                  >
                    <a @click="showParamsDisabled = !showParamsDisabled">{{
                        showParamsDisabled ? 'Ocultar' : 'Mostrar'
                      }}
                      parâmetros desabilitados</a>
                  </span>
                  <hr v-if="showParamsDisabled && !!integrationUpdating" />

                  <b-table
                    v-if="showParamsDisabled"
                    :data="getFilteredParams(integrationParams, false)"
                    :bordered="true"
                    :striped="false"
                    :narrowed="false"
                    :hoverable="false"
                    :loading="false"
                    :focusable="false"
                    :mobile-cards="true"
                  >
                    <b-table-column
                      field="exibition_name"
                      label="Texto de exibição"
                      v-slot="props"
                    >
                      {{ props.row.exibition_name }}
                    </b-table-column>

                    <b-table-column
                      field="custom_code"
                      label="Código do campo"
                      v-slot="props"
                    >
                      {{ props.row.custom_code }}
                    </b-table-column>

                    <b-table-column
                      field="type"
                      label="Tipo"
                      v-slot="props"
                    >
                      {{ props.row.type }}
                    </b-table-column>

                    <b-table-column
                      field="status"
                      label="Status"
                    >
                      <b-tag type="is-danger"> DESATIVADO </b-tag>
                    </b-table-column>

                    <b-table-column
                      field="delete"
                      width="200"
                      label="Ativar"
                      v-slot="props"
                    >
                      <b-button
                        type="is-success is-small is-fullwidth"
                        @click="changeParamEnabled(props.row, true)"
                      >Ativar</b-button>
                    </b-table-column>
                  </b-table>

                  <div v-if="
                      showParamsDisabled &&
                        !getFilteredParams(integrationParams, false).length
                    ">
                    <b-message class="warning-message">
                      Não há parâmetros inativos
                    </b-message>
                    <p></p>
                  </div>
                </div>
              </div>
            </b-field>
            <b-field>
              <b-checkbox v-model="send_menu">Enviar menu após completar requisição</b-checkbox>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button
              class="button"
              type="button"
              @click="closeModal"
            >
              Fechar
            </button>
            <button
              v-if="!!integrationUpdating"
              class="button is-primary"
              @click="updateIntegration"
            >
              Atualizar
            </button>
            <button
              v-else
              class="button is-primary"
              @click="addIntegration"
            >
              Gravar
            </button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
// import { get } from '../../utils/api'

export default {
  name: 'ModalInsertSystemIntegration',
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    integrationUpdating: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      id: '',
      name: '',
      custom_code: '',
      exibitionName: '',
      customCode: '',
      type: 'string',
      integrationParams: [],
      showParamsDisabled: false,
      send_menu: false,
      draggingRow: null,
      draggingRowIndex: null,
      draggingColumn: null,
      draggingColumnIndex: null
    }
  },
  async created () {
    if (this.integrationUpdating) {
      this.id = this.integrationUpdating.id
      this.name = this.integrationUpdating.name
      this.custom_code = this.integrationUpdating.custom_code
      this.integrationParams =
        this.integrationUpdating.external_system_integration_params
      this.send_menu = this.integrationUpdating.send_menu
    }
  },
  methods: {
    mouseenter (payload, event) {
      event.target.closest('tr').classList.add('is-selected')
      event.target.closest('tr').style.cursor = 'grab'
    },
    mouseleave (payload, event) {
      event.target.closest('tr').classList.remove('is-selected')
      event.target.closest('tr').style.cursor = 'default'
    },
    dragstart (payload) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.index
      payload.event.dataTransfer.effectAllowed = 'copyMove'
    },
    dragover (payload) {
      payload.event.dataTransfer.dropEffect = 'move'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragleave (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    drop (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      const droppedOnRowIndex = payload.index
      this.integrationParams.splice(this.draggingRowIndex, 1)
      this.integrationParams.splice(droppedOnRowIndex, 0, this.draggingRow)
      this.integrationParams = this.integrationParams.map((param, index) => {
        return {
          ...param,
          order_number: index + 1
        }
      })
      payload.event.preventDefault()
    },
    closeModal () {
      this.$emit('closeModal')
    },
    changeParamEnabled (param, enabled) {
      this.integrationParams = this.integrationParams.map(c => {
        return {
          ...c,
          enabled: c.id === param.id ? enabled : c.enabled
        }
      })
    },
    deleteParam (param) {
      this.integrationParams = this.integrationParams.filter(
        p => p.id !== param.id
      )
    },
    showColumn (param) {
      console.warn(param)
    },
    addParam () {
      if (this.exibitionName.length <= 1 && this.type !== 'checkpoint') {
        this.$buefy.dialog.alert({
          title: 'Texto de exibição muito curto',
          message:
            'O <b>texto de exibição</b> informado é inválido. O novo nome deve ter no mínimo 2 caracteres!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      if (this.customCode.length <= 1) {
        this.$buefy.dialog.alert({
          title: 'Código muito curto',
          message:
            'O <b>código do campo</b> informado é inválido. O novo código deve ter no mínimo 2 caracteres!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      this.integrationParams = [
        ...this.integrationParams,
        {
          exibition_name: this.exibitionName,
          custom_code: this.customCode,
          type: this.type,
          enabled: true,
          order_number: this.integrationParams.length + 1
        }
      ]

      this.exibitionName = ''
      this.customCode = ''
      this.type = 'string'
    },
    updateIntegration () {
      if (this.name && this.name.length >= 0 && this.name.length <= 2) {
        this.$buefy.dialog.alert({
          title: 'Nome muito curto',
          message:
            'O <b>nome</b> informado é inválido. O novo nome deve ter no mínimo 2 caracteres!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      this.emitUpdateIntegration({
        id: this.integrationUpdating.id,
        name: this.name,
        custom_code: this.custom_code,
        send_menu: this.send_menu,
        external_system_integration_params: this.getFilteredParams(
          this.integrationParams,
          true
        )
      })
    },
    addIntegration () {
      if (this.name && this.name.length >= 0 && this.name.length <= 2) {
        this.$buefy.dialog.alert({
          title: 'Nome muito curto',
          message:
            'O <b>nome</b> informado é inválido. O novo nome deve ter no mínimo 2 caracteres!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      this.emitAddIntegration({
        name: this.name,
        custom_code: this.custom_code,
        send_menu: this.send_menu,
        external_system_integration_params: this.getFilteredParams(
          this.integrationParams,
          true
        )
      })
    },
    getFilteredParams (params, enabled) {
      if (!params) return []
      return params.filter(param => param.enabled === enabled)
    },
    emitUpdateIntegration (integration) {
      this.$emit('updateIntegration', integration)
    },
    emitAddIntegration (integration) {
      this.$emit('addIntegration', integration)
    }
  }
}
</script>

<style scoped>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}
</style>
