<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Perfis de permissões
    </hero-bar>
    <section class="section is-main-section">
      <b-loading v-if="fetchingPermissions" :is-full-page="true" v-model="fetchingPermissions" :can-cancel="false">
      </b-loading>
      <ModalInsertPermission
        v-if="isComponentModalActive"
        :allAccounts="allPhonesEnabled"
        :permissionUpdating="permissionUpdating"
        :availablePermissionItems="availablePermissionsItems"
        v-on:addPermission="handleAddPermission"
        v-on:updatePermission="handleUpdatePermission"
        v-on:closeModal="closeModal"
        :isComponentModalActive="isComponentModalActive" />
      <permissions-list v-else :permissions='allPermissions' v-on:updatePermission="openUpdatePermission" v-on:removePermission="removePermission"/>
      <FabButton v-on:addClick="handleAddClick" iconClass="fas fa-pen-fancy" title="Novo perfil de permissão" />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import FabButton from '@/components/FabButton.vue'
import PermissionsList from '@/components/permissions/PermissionsList.vue'
import { mapActions, mapGetters } from 'vuex'
import ModalInsertPermission from '@/components/modals/ModalInsertPermission.vue'

export default {
  name: 'Permission',
  components: {
    HeroBar,
    TitleBar,
    FabButton,
    PermissionsList,
    ModalInsertPermission
  },
  data () {
    return {
      isComponentModalActive: false,
      permissionUpdating: null,
      updateSystem: false,
    }
  },
  computed: {
    ...mapGetters(['allPermissions', 'fetchingPermissions', 'availablePermissionsItems', 'allPhonesEnabled']),
    titleStack () {
      return ['Configurações', 'Perfis de permissões']
    },
  },
  methods: {
    ...mapActions(['updatePermission', 'fetchPermissions', 'addPermission', 'deletePermission', 'fetchAvailablePermissionItems']),
    openUpdatePermission(permission) {
      this.permissionUpdating = permission;
      this.isComponentModalActive = true;
      this.updateSystem = true;
    },
    handleAddClick () {
      this.isComponentModalActive = !this.isComponentModalActive
    },
    removePermission(permission) {
      this.deletePermission(permission);
    },
    handleUpdatePermission(permission) {
      this.closeModal();
      this.updatePermission(permission).catch((err) =>
        this.$buefy.dialog.alert({
          title: 'Erro ao atualizar permissão',
          message: err.response?.data?.message ?? `Ocorreu um <b>erro</b> ao atualizar os dados da permissão. Recarregue a tela e tente novamente!`,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      )
    },
    handleAddPermission(permission) {
      this.closeModal();
      this.addPermission(permission).catch(() =>
        this.$buefy.dialog.alert({
          title: 'Erro ao inserir permissão',
          message: 'Ocorreu um <b>erro</b> ao inserir os dados da permissão. Recarregue a tela e tente novamente!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      )
    },
    closeModal() {
      this.isComponentModalActive = false;
      this.updateSystem = false;
    },
  },
  created() {
    this.fetchPermissions();
    this.fetchAvailablePermissionItems();
  }
}
</script>
